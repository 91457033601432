import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ee021e3 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _4546a03b = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5fda97bc = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _670fbc1f = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _2d6c8ce0 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _784dfe30 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3c7ff53f = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _3d8b0a7f = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _b6c14008 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _73d8081e = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _058b4238 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _0957e0fa = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _8c771980 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _3baab1a2 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _c7c90078 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _08376c9f = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _0f432169 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _cd04cf42 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _42b558ae = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _7a362a7a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _5ee021e3,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _4546a03b,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _5fda97bc,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _670fbc1f,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _2d6c8ce0,
    name: "receipt"
  }, {
    path: "/search",
    component: _784dfe30,
    name: "search"
  }, {
    path: "/search-booking",
    component: _3c7ff53f,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _3d8b0a7f,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _b6c14008,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _73d8081e,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _058b4238,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _0957e0fa,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _8c771980,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _3baab1a2,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _c7c90078,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _08376c9f,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _0f432169,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _cd04cf42,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _42b558ae,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _7a362a7a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
